import 'core-js/modules/es.array.iterator'; // Make Gmaps Loader work on IE11
import { Loader } from '@googlemaps/js-api-loader/dist/index.umd';
import ContextualDisplayer from '../cookies/ContextualDisplayer';
import getIcon from '../../map/icon';

/* global google, GMAP_API_KEY */

document.addEventListener('DOMContentLoaded', () => {
  const $locations = document.querySelectorAll('.js-location');
  const $locationHolders = document.querySelectorAll('.js-map-placeholder');
  function initLocation() {
    $locations.forEach($location => {
      const $map = $location.querySelector('.js-location-map');
      const coords = {
        lat: parseFloat($location.getAttribute('data-lat')),
        lng: parseFloat($location.getAttribute('data-lng')),
      };

      const map = new google.maps.Map($map, {
        center: coords,
        zoom: 10,
        disableDefaultUI: true,
        zoomControl: true,
      });

      const marker = new google.maps.Marker({
        position: coords,
        map,
        icon: getIcon('primary'),
      });

      if ($location.getAttribute('data-url')) {
        marker.addListener('click', () => {
          window.open($location.getAttribute('data-url'), '_blank');
        });
      }
    });
  }

  if ($locationHolders.length) {
    (window._axcb = window._axcb || []).push(sdk => {
      sdk.on('cookies:complete', choices => {
        const event = new CustomEvent('cookies:updateChoice', {
          detail: choices,
        });
        document.dispatchEvent(event);
        if (event.detail.gmaps && !window?.google?.maps) {
          new Loader({
            apiKey: GMAP_API_KEY || '',
            version: 'weekly',
          })
            .load()
            .then(initLocation)
            .catch(() => {});
        }
      });
    });

    $locationHolders.forEach($locationHolder => {
      const displayer = new ContextualDisplayer($locationHolder, 'gmaps', consent => {
        if (consent) {
          $locationHolder.setAttribute('hidden', '');
        } else {
          $locationHolder.removeAttribute('hidden');
        }
      });
    });
  }
});
